import React from 'react'
import { Tabs } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LoginPanel from './common/LoginPanel'
import orientationImg from './common/orientation.svg'

const useStyles = makeStyles(theme => ({
  appHeader: {
    minHeight: 60,
    backgroundColor: theme.palette.custom.secondary,
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position:'absolute',
    width: '100%',
  },
  headerImage: {
    alignSelf: 'center',
    width:40,
    height:40,
    marginRight: 15,
  },
}))

function AppHeader({ openPage, setOpenPage, userAuthenticated }) {
  const classes = useStyles()

  if (!userAuthenticated) {
    return (
      <Paper square elevation={5} className={classes.appHeader}>
        <div></div>
        <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '15px', width: '30%'}}>
          <LoginPanel />
        </div>
      </Paper>
    )
  }

  return (
    <Paper square elevation={0} className={classes.appHeader}>
      <div style={{display:'flex', alignItems:'center', paddingLeft: '15px', gap: 10, width: '30%'}}>
        <img className={classes.headerImage} alt={''} src={orientationImg}/>
        <Typography variant='h6' style={{color: 'white'}}>
          {'Firia Coffee Room'}
        </Typography>
      </div>
      <Tabs
        variant='fullWidth'
        value={openPage}
        onChange={(_, page) => {
          setOpenPage(page)
        }}
      >
        {/* <Tab color='primary' style={{color: 'white'}} label='Order Builder' value={'builder'} /> */}
        {/* <Tab color='primary' style={{color: 'white'}} label='Reseller Controller' value='reseller' /> */}
      </Tabs>
      <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '15px', width: '30%'}}>
        <LoginPanel />
      </div>
    </Paper>
  )
}

export { AppHeader }