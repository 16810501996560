import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { SKUCard } from './common/SKUDecoder'
import { RespButton } from './common/buttons'
import { postSim, adjustColor } from './common/utils'
import { useLogin } from './common/LoginContext'
import { useMissionPacks } from './common/MissionPackContext'
import MenuItem from '@material-ui/core/MenuItem'
import { OutlinedTextField, OutlinedSelect, OutlinedCheckBox } from './common/fields'
import GenericDialog from './common/GenericDialog'
import { v4 as uuid } from 'uuid'

const isDev = window.location.href.startsWith('http://localhost')
const useStyles = makeStyles(theme => ({
  appHeader: {
    height: 50,
    backgroundColor: theme.palette.custom.secondary,
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position:'absolute',
    width: '100%',
  },
  pageContainer: {
    padding: 20,
  },
  paper: {
    maxWidth: '90%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.custom.primary,
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      height: 45,
      '& fieldset': {
        color: theme.palette.custom.quaternary,
      },
      '&.Mui-focused fieldset': {
        color: theme.palette.custom.quaternary,
      },
    },
    '& .MuiInputLabel-root': {
      top: -5,
    },
    '& .MuiInputLabel-shrink':{
      top: 0,
      color: theme.palette.custom.quaternary,
    },
  },
  button: {
    backgroundColor: theme.palette.custom.accent,
    '&:hover': {
      backgroundColor: adjustColor(theme.palette.custom.accent, -10),
    },
  },
  rowFlex: {
    display:'flex',
    flexDirection:'row',
  },
  headerContainer: {
    width:'100%',
    paddingBottom:5,
  },
  headerDivider: {
    width:'100%',
    paddingTop: 12,
    paddingLeft: 10,
  },
  buttonContainer: {
    height: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    marginBottom: '10px',
  },
  tableContainer: {
    backgroundColor: '#4b4b4b',
    maxHeight: '90%',
  },
  skuCardPaper: {
    backgroundColor: '#C8C8C8',
  },
  skuCardFooter: {
    backgroundColor: '#A2A2A2',
  },
  skuBuilderTextFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    width: 325,
    gap: 15,
  },
  rightSideContainer: {
    display: 'flex',
    width: '100%',
    gap: 35,
  },
  orderCardContainer: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  headerImage: {
    alignSelf: 'center',
    width:40,
    height:40,
    marginRight: 15,
  },
  cellHover: {
    '&:hover': {
      backgroundColor: 'green !important',
    },
  },
  dialogContentContainer: {
    display:'flex',
    flexDirection: 'column',
    padding: '5px 0px',
  },
}))

const toISOLocal = (d) => {
  var z  = n =>  ('0' + n).slice(-2)

  return d.getFullYear() + '-' +
         z(d.getMonth()+1) + '-' +
         z(d.getDate()) + 'T' +
         z(d.getHours()) + ':'  +
         z(d.getMinutes())
}


const generateFiriaOrderId = () => {
  const id = uuid()
  const split = id.split('-')
  return 'FIRIA-' + split.slice(1, split.length).join('-')
}

const getOrderFields = () => {
  return ({
    orderNumber: generateFiriaOrderId(),
    purchaseSource: 'Firia',
    purchaserName: '',
    purchaserEmail: '',
    purchased: toISOLocal(new Date()),
    licenses: [],
    sendEmail: false,
  })
}

const SKU_DEFAULTS = () => {
  return {
    term: '365',
    expires: true,
    type: 'Assigned',
    seats: '1',
    product: 'CTE',
  }
}

const OrderInfoFields = (props) => {
  const disabled = props.disabled

  return (
    <div>
      <div
        style={{marginBottom: 15}}
      >
        <OutlinedSelect
          value={props.orderFields['purchaseSource']}
          onChange={ev=>props.handleOrderNumber(ev.target.value)}
          label='Purchase Source'
          style={{width: 250}}
          disabled={disabled}
        >
          <MenuItem value={'Firia'}>{'Firia'}</MenuItem>
          <MenuItem value={'Amazon'}>{'Amazon'}</MenuItem>
        </OutlinedSelect>
      </div>
      {Object.keys(props.orderFields).map((field) => {
        return (
          field !== 'purchased' && field !== 'purchaseSource' && field !== 'sendEmail' ?
            <div
              key={field}
              style={{marginBottom: 15}}
            >
              {field === 'licenses' ?
                null:
                <OutlinedTextField
                  disabled={props.fetchStatus === 'fetching' || disabled}
                  label={props.formatFieldTitle(field)}
                  onChange={ev=>props.onFieldChange(field, ev.target.value)}
                  value={props.orderFields[field]}
                  style={{width: 250}}
                />
              }
            </div> :
            null
        )
      })}
      <div style={{marginBottom: 15}}>
        <OutlinedTextField
          label='Purchased'
          type='datetime-local'
          onChange={ev=>props.onFieldChange('purchased', ev.target.value)}
          value={props.orderFields['purchased']}
          style={{width: 250}}
          disabled={disabled}
        />
      </div>
      <div style={{marginBottom: 15}}>
        <OutlinedCheckBox
          checked={props.orderFields['sendEmail']}
          onClick={ev=>props.onFieldChange('sendEmail', !props.orderFields['sendEmail'])}
          label='Send Customer Email'
          style={{width: 250}}
          disabled={disabled || isDev}
        />
      </div>
    </div>
  )
}

function OrderBuilderPanel() {
  const classes = useStyles()
  const [orderFields, setOrderFields] = React.useState(getOrderFields())
  const [skuItems, setSkuItems] = React.useState([])
  const [fetchStatus, setFetchStatus] = React.useState('')
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)
  const [skuFields, setSkuFields] = React.useState(SKU_DEFAULTS)
  const [loginState] = useLogin()
  const { missionPacks } = useMissionPacks()

  console.log(missionPacks)

  const onFieldChange = (key, value) => {
    var copyOfFields = {...orderFields}
    copyOfFields[key] = value
    setOrderFields(copyOfFields)
  }

  const onSKUFieldChange = (key, value) => {
    var copyOfFields = {...skuFields}
    copyOfFields[key] = value
    setSkuFields(copyOfFields)
  }

  const handleCreateOrder = () => {
    const orderData = {
      userId: '1',
      orderNumber: orderFields.orderNumber,
      purchaseSource: orderFields.purchaseSource,
      purchaserName: orderFields.purchaserName.trim(),
      purchaserEmail: orderFields.purchaserEmail.trim(),
      purchased: orderFields.purchased,
      sendEmail: isDev ? false : orderFields.sendEmail,
      skuItems: skuItems,
      packList: missionPacks,
    }
    return postSim('createOrder', loginState?.user, orderData)
  }

  const handleCreateOrderSuccess = () => {
    setOrderFields(getOrderFields())
    setSkuItems([])
    setConfirmationDialogOpen(false)
  }

  const formatFieldTitle = (title) => {
    const titleArr = title.split(/(?=[A-Z])/)
    titleArr[0] = titleArr[0].charAt(0).toUpperCase() + titleArr[0].slice(1)
    return titleArr.join(' ')
  }

  const formatSku = (skuFields, addSeats=false) => {
    var tempSku = skuFields.product
    if (addSeats){
      tempSku += skuFields.seats
    }
    if (skuFields.expires){
      if (skuFields.type === 'Assigned'){
        tempSku += 'D03' + skuFields.term
      } else {
        tempSku += 'D01' + skuFields.term
      }
    } else {
      if (skuFields.type === 'Assigned'){
        tempSku += 'D02'
      }
    }

    return tempSku
  }

  const addProductToSku = () => {
    var skuItemsCopy = [...skuItems]
    skuItemsCopy.push({sku: formatSku(skuFields), quantity: skuFields.seats})
    setSkuItems(skuItemsCopy)
  }

  const handleProductDelete = (index) => {
    var skuItemsCopy = [...skuItems]
    skuItemsCopy.splice(index, 1)
    setSkuItems(skuItemsCopy)
  }


  const handleOrderNumber = (source) => {
    var copyOfFields = {...orderFields}
    if (source === 'Firia'){
      copyOfFields.orderNumber = generateFiriaOrderId()
    } else {
      copyOfFields.orderNumber = ''
    }
    copyOfFields.purchaseSource = source
    setOrderFields(copyOfFields)
  }

  return (
    <>
      <Card variant='outlined' style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
        <div style={{padding: '20px 40px'}}>
          <div className={classes.buttonContainer}>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems:'flex-start', gap: 75}}>
            <OrderInfoFields
              orderFields={orderFields}
              onFieldChange={onFieldChange}
              formatFieldTitle={formatFieldTitle}
              fetchStatus={fetchStatus}
              handleOrderNumber={handleOrderNumber}
            />
            <div className={classes.rightSideContainer}>
              <div className={classes.skuBuilderTextFields} style={{width: '35%'}}>
                <OutlinedSelect
                  value={missionPacks.length > 0 ? skuFields.product : ''}
                  onChange={ev=>onSKUFieldChange('product', ev.target.value)}
                  label='Product'
                  style={{width: '100%'}}
                >
                  {missionPacks.map(product => (
                    <MenuItem key={product.id} value={product.id}>{product.title}</MenuItem>
                  ))}
                </OutlinedSelect>
                <OutlinedTextField
                  label={'Seats'}
                  onChange={ev=>onSKUFieldChange('seats', ev.target.value > -1 ? ev.target.value : 0)}
                  type='number'
                  value={skuFields.seats}
                  style={{width: '100%'}}
                />
                <OutlinedSelect
                  value={skuFields.type}
                  onChange={ev=>onSKUFieldChange('type', ev.target.value)}
                  label='Type'
                  style={{width: '100%'}}
                >
                  <MenuItem value={'Floating'}>{'Floating'}</MenuItem>
                  <MenuItem value={'Assigned'}>{'Assigned'}</MenuItem>
                </OutlinedSelect>
                <OutlinedCheckBox
                  checked={skuFields.expires}
                  onClick={ev=>onSKUFieldChange('expires', !skuFields.expires)}
                  label='Expires'
                  style={{width: '100%'}}
                />
                <OutlinedTextField
                  label={'Expires In (days)'}
                  disabled={!skuFields.expires}
                  value={skuFields.term}
                  onChange={ev=>onSKUFieldChange('term', ev.target.value > -1 ? ev.target.value : 0)}
                  type='number'
                  style={{width: '100%'}}
                />
                <Button
                  className={classes.button}
                  onClick={addProductToSku}
                  variant='contained'
                  disabled={skuFields.seats === 0}
                >
                          Add Product To Order
                </Button>
              </div>
              <SKUCard
                skuItems={skuItems}
                handleDelete={handleProductDelete}
                height={'80vh'}
              />
            </div>
          </div>
        </div>
        <div style={{ height:50, display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight: 10}}>
          <div></div>
          <Button
            className={classes.button}
            onClick={()=>setConfirmationDialogOpen(true)}
            variant='contained'
            disabled={skuItems.length < 1 || !orderFields.orderNumber}
          >
                    Submit Order
          </Button>
        </div>
        {/* <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%'}}>
          <LicenseUpdaterButton />
        </div> */}
      </Card>
      <GenericDialog
        open={confirmationDialogOpen}
        keepMounted
        onClose={()=>setConfirmationDialogOpen(false)}
        title={
          <Typography>
                    Order Creation Confirmation
          </Typography>
        }
        buttons={
          <>
            <RespButton
              className={classes.button}
              buttonComponent={Button}
              onClick={handleCreateOrder}
              successCb={handleCreateOrderSuccess}
              status={fetchStatus}
              setStatus={setFetchStatus}
              variant='contained'
              resetStatus={confirmationDialogOpen}
            >
                      Create Order
            </RespButton>
            <Button
              onClick={()=>setConfirmationDialogOpen(false)}
              variant='outlined'
            >
                      Cancel
            </Button>
          </>
        }
      >
        <div className={classes.dialogContentContainer}>
          <Typography variant='body1' style={{paddingBottom: 15}} >
            {'Are you sure you want to create this order?'}
          </Typography>
          <div style={{display:'flex', gap: 15}}>
            <OrderInfoFields
              orderFields={orderFields}
              onFieldChange={onFieldChange}
              formatFieldTitle={formatFieldTitle}
              fetchStatus={fetchStatus}
              disabled
            />
            <SKUCard
              skuItems={skuItems}
              height={400}
            />
          </div>
        </div>
      </GenericDialog>
    </>
  )
}

export { OrderBuilderPanel }
