import React from 'react'
import { Card } from '@material-ui/core'
import authFetchHandler from './common/tools/auth/authFetchHandler'
import { Button, Typography } from '@material-ui/core'

async function fetchResellerInfo() {
  try {
    const res = await authFetchHandler('analytics/reseller-info')
    if (res.ok) {
      return await res.json()
    }
  } catch (err) {
    console.error(err)
  }
}


function ResellerControlPage() {
  const [resellerInfo, setResellerInfo] = React.useState({})
  const [selectedResellerId, setSelectedResellerId] = React.useState(null)
  const [, setSelectedClientId] = React.useState(null)
  const resellerIds = Object.keys(resellerInfo)
  let clientIds = []
  if (resellerIds.length > 0) {
    try {
      clientIds = Object.keys(resellerInfo[selectedResellerId].clients)
    } catch {
      //
    }
  }

  React.useEffect(() => {
    const getResellerInfo = async () => {
      const resp = await fetchResellerInfo()
      setResellerInfo(resp)
      const defaultSelectedResellerId = Object.keys(resp)[0]
      setSelectedResellerId(defaultSelectedResellerId)
      setSelectedClientId(Object.keys(resp[defaultSelectedResellerId].clients)[0])
    }
    getResellerInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log(resellerInfo)

  return (
    <div style={{height: '100%'}}>
      <Card variant='outlined' style={{height: '100%', padding: '20px 40px', display: 'flex', justifyContent: 'center', gap: 25}}>
        <div style={{width: 350, height: '100%'}}>
          <div style={{display: 'flex', alignItems:'flex-start'}}>
            <Typography variant='h5'>
              {'Reseller'}
            </Typography>
          </div>
          <Card variant='outlined' style={{flexGrow: 1}}>
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
              {resellerIds.map(resellerId =>
                <Button style={{textTransform: 'none'}}>
                  {resellerInfo[resellerId].email}
                </Button>
              )}
            </div>
          </Card>
        </div>
        <div>
          <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: 300, display: 'flex', alignItems:'flex-start'}}>
              <Typography variant='h5'>
                {'Client'}
              </Typography>
            </div>
            <div style={{display: 'flex', alignItems:'flex-start'}}>
              <Typography variant='h5'>
                {'Client Info'}
              </Typography>
            </div>
          </div>
          <Card variant='outlined' style={{display: 'flex'}}>
            <div style={{height: '100%', borderRight: 'rgba(0,0,0,0.03)', width: 350}}>
              {clientIds.map(clientId =>
                <Button style={{textTransform: 'none', textOverflow: 'ellipsis'}}>
                  {resellerInfo[selectedResellerId].clients[clientId].email}
                </Button>
              )}
            </div>
            <div style={{width: '100%'}}>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export { ResellerControlPage }